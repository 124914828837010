import * as bootstrap from 'bootstrap';

const dSwitch = document.querySelector("#dark-switch")

dSwitch.addEventListener('change', function () {
    const body = document.querySelector('body')
    const headline = document.querySelector('h1')
    const textarea = document.querySelector('#text-area')

    if (this.checked) {
        body.classList.add('bg-dark', 'text-light')
        headline.classList.replace('text-primary', 'text-light')
        textarea.classList.add('bg-dark', 'text-light')
} else {
       body.classList.remove('bg-dark', 'text-light')
        headline.classList.replace('text-light', 'text-primary')
        textarea.classList.remove('bg-dark', 'text-light')
    
}

} )
